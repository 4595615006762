@tailwind base;
@tailwind components;
@tailwind utilities;

a {
    color: black;
}

.Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 12px;
  line-height: 1;
  font-weight: 600;
  height: 30px;
  cursor: pointer;
}

.Airdrop-Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 0 15px;
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
  height: 36px;
  cursor: pointer;
}

.green {
  background-color: var(--green4);
  color: var(--green11);
}
.green:hover {
  background-color: var(--green5);
}
