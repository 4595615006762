@import '@radix-ui/colors/blackA.css';
@import '@radix-ui/colors/mauve.css';
@import '@radix-ui/colors/violet.css';
@import '@radix-ui/colors/green.css';

/* reset */
button,
h3 {
  all: unset;
}

.assetImg {
  border-radius: 100%;
  margin-left: -8px;
}

.platformAssetImg {
  border-radius: 100%;
}

.aprArea {
  width: 120px;
}

.TabsRoot {
  display: flex;
  flex-direction: column;
}

.TabsList {
  flex-shrink: 0;
  display: flex;
}

.TabsTrigger {
  background-color: white;
  height: 35px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 1;
  color: var(--mauve11);
  user-select: none;
}

.TabsTrigger:hover {
  color: var(--violet9);
}
.TabsTrigger[data-state='active'] {
  color: var(--violet9);
  box-shadow: inset 0 -0.5px 0 0 currentColor, 0 0.5px 0 0 currentColor;
}
.TabsTrigger:focus {
  position: relative;
}

.TabsContent {
  flex-grow: 1;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 0px;
  /* background-color: white; */
  outline: none;
}

.Input {
  flex: 1 0 auto;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 14px;
  line-height: 1;
  color: var(--violet12);
  box-shadow: 0 0 0 1px var(--violet7);
  height: 30px;
}

.Inner-Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  padding: 0 15px;
  font-size: 10px;
  line-height: 1;
  font-weight: 600;
  height: 20px;
  cursor: pointer;
  width: 16px;
  margin-left: -50px;
}


.Small-Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 11px;
  line-height: 1;
  font-weight: 600;
  height: 20px;
  cursor: pointer;
}



.AccordionRoot {
  border-radius: 4px;
  width: 100%;
  background-color: var(--mauve6);
  box-shadow: 0 2px 10px var(--blackA4);
}

.AccordionItem {
  overflow: hidden;
  margin-top: 1px;
}

.AccordionItem:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.AccordionItem:focus-within {
  position: relative;
  z-index: 1;
}

.AccordionTitle {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: transparent;
  align-items: center;
  line-height: 1;
  color: gray;
  /* background-color: white; */
}

.AccordionTrigger {
  flex: 1;
  display: flex;
  align-items: center;
  line-height: 1;
  box-shadow: 0 1px 0 var(--mauve6);
  background-color: white;
}

.AccordionTrigger:hover {
  background-color: var(--mauve2);
}

.AccordionContent {
  overflow: hidden;
  font-size: 15px;
  color: var(--mauve11);
  background-color: var(--mauve2);
}
.AccordionContent[data-state='open'] {
  animation: slideDown 100ms cubic-bezier(0.87, 0, 0.13, 1);
}
.AccordionContent[data-state='closed'] {
  animation: slideUp 100ms cubic-bezier(0.87, 0, 0.13, 1);
}

.AccordionContentText {
  padding: 10px 10px;
}

.AccordionChevron {
  flex-grow: 0;
  color: var(--violet10);
  transition: transform 100ms cubic-bezier(0.87, 0, 0.13, 1);
}
.AccordionTrigger[data-state='open'] > .AccordionChevron {
  transform: rotate(180deg);
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}